<template>
  <div class="container">
    <el-form :loading="loading" ref="form" :model="preFrom" label-width="80px"></el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">
          新建项目
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-refresh-right" size="mini" @click="getDataList">
          刷新
        </el-button>
      </el-col>
    </el-row>
    <el-table border stripe :loading="loading" :data="DataList" style="margin-top: 5px;">
      <el-table-column label="ID" align="center" prop="id"/>
      <!-- Project.vue 的 table 列修改 -->
      <el-table-column label="项目名称" align="center" prop="projectName">
        <template slot-scope="scope">
          <el-button type="text" @click="gotoRolePage(scope.row.id)">
            {{ scope.row.projectName }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="备注" align="center" prop="remark"/>
      <el-table-column label="创建时间" align="center" prop="createTime">
        <template slot-scope="scope">
          {{ parseTime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间" align="center" prop="updateTime">
        <template slot-scope="scope">
          {{ parseTime(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180px">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">
            修改
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新建/修改弹窗 -->
    <el-dialog :title="dialog.title" :visible.sync="dialog.open" width="40%" append-to-body>
      <el-form v-loading="loading" :model="preFrom" ref="ruleForm" label-width="100px">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="preFrom.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
              v-model="preFrom.remark"
              type="textarea"
              :rows="3"
              placeholder="请输入项目备注"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {checkTokenBeforeRouteEnter} from '@/utils/authoVerify'
import {parseTime} from "@/utils/untilsMethod";
import {getProjectList, addProject, updateProject, deleteProject} from "@/api/project";
import { cloneDeep } from 'lodash';
import {getAdvertiserList} from "@/api/advertiser";

export default {
  beforeRouteEnter(to, from, next) {
    checkTokenBeforeRouteEnter(to, from, next);
  },
  data() {
    return {
      loading: false,
      DataList: [],
      dialog: {
        open: false,
        title: '',
        type: ''
      },
      preFrom: {
        id: undefined,
        projectName: undefined,
        remark: undefined,
      },
      form: {}
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    parseTime,
    // 跳转到规则设置页
    gotoRolePage(projectId) {
      this.$router.push({
        path: '/monitorRole',
        query: {
          projectId: projectId
        }
      })
    },
    // 删除
    handleDelete(id) {
      this.$confirm("删除后将不可恢复, 是否继续?", "删除项目", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteProject({id: id}).then(response => {
          this.$message.success(response.msg)
          this.getDataList()
        })
      })
    },
    // 修改
    handleUpdate(item) {
      this.dialog.title = '修改项目'
      this.dialog.type = 'update'
      this.dialog.open = true
      this.preFrom = cloneDeep(item)
    },
    // 新增
    handleAdd() {
      this.dialog.title = '新建项目'
      this.dialog.type = 'add'
      this.dialog.open = true
    },
    // 获取列表
    getDataList() {
      this.loading = true
      getProjectList().then(response => {
        this.DataList = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    // 提交表单
    onSubmit() {
      if (!this.preFrom.projectName) {
        this.$message.error("项目名称不能为空")
        return
      }
      this.getForm()
      const apiCall = this.dialog.type === 'add'
          ? addProject(this.form)
          : updateProject(this.form)

      apiCall.then(response => {
        this.$message.success(response.msg)
        this.getDataList()
        this.dialog.open = false
        this.resetForm()
      }).catch(() => {
        this.$message.error("请求错误")
      })
    },
    // 重置表单
    resetForm() {
      this.preFrom = {
        id: undefined,
        projectName: undefined,
        remark: undefined
      }
      this.form = {}
    },
    getForm() {
      this.form = cloneDeep(this.preFrom);
      this.form.createTime = undefined;
      this.form.updateTime = undefined
    },
  }
}
</script>

<style>
.container {
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
